<template>
  <section class='section'>
    <modal-add
      v-if="modalAdd"
      :close="showModal"
      :catalogCategoriesList="catalogCategoriesList"
      :saveCharacteristic="saveCharacteristic"
    />
    <modal-edit
      :close="showModalEdit"
      :catalogCategoriesList="catalogCategoriesList"
      :editCharacteristic="editCharacteristic"
      :editElement="editElement"
      v-if="modalEdit"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Caracteristicas para los productos</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button @click="showModal" class="button button-givu">Agregar</button>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <nav class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <div class="control">
                <input class="input" type="text" v-model="search" @keyup="searchCharacteristics" placeholder="Buscador...">
              </div>
            </div>
            <div class="level-item">
              <div class="select">
                <select class="select" v-model="filter" @change="changeStatus">
                  <option value=""> Todos </option>
                  <template v-for="category in catalogCategoriesList">
                    <option :value="category.id" :key="category.id"> {{category.name}} </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="characteristicsList"
          :loading=loading
        >
        <div
          slot="config"
          slot-scope="scope"
        >
          <i class="material-icons icon-action" @click="showModalEdit(scope.row)">edit</i>
        </div>
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Categorias',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination'),
    modalAdd: () => import('@/components/Characteristics/ModalAdd'),
    modalEdit: () => import('@/components/Characteristics/ModalEdit')
  },
  data () {
    return {
      modalAdd: false,
      modalEdit: false,
      page: 1,
      pages: '',
      loading: false,
      characteristicsList: [],
      catalogCategoriesList: [],
      editElement: {},
      search: '',
      filter: '',
      columnsData: [
        {
          id: 'id', header: '#', class: 'column-mobile'
        },
        { id: 'name', header: 'Característica', class: 'column-mobile' },
        {
          id: 'category',
          header: 'Categoría',
          class: 'column-mobile',
          accessor: (row) => row.category || 'Sin categoría'
        },
        {
          id: 'created_at',
          header: 'Fecha de Registro',
          class: 'column-mobile',
          accessor: (row) => moment(row.created_at).format('DD-MM-YYYY')
        },
        {
          id: 'active',
          header: 'Estatus',
          class: 'column-mobile',
          accessor: (row) => row.active ? 'Activo' : 'Inactivo'
        },
        { id: 'config', name: 'config', header: 'Acciones', class: 'column-mobile has-text-centered' }
      ]
    }
  },
  methods: {
    ...mapActions(['getCharacteristics', 'getCatalogCategories', 'createCharacteristic', 'updateCharacteristic']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.getCharacteristics({ page, search: this.search, filter: this.filter })
        .then(resp => {
          this.loading = false
          this.characteristicsList = resp.payload
          this.pages = resp.totalPages
        })
    },
    showModal () {
      this.modalAdd = !this.modalAdd
    },
    showModalEdit (data) {
      this.modalEdit = !this.modalEdit
      this.editElement = data
    },
    saveCharacteristic (data) {
      this.createCharacteristic(data)
        .then(response => {
          if (response) {
            this.getCharacteristics({ page: 1, size: DEFAULT_DISPLAY_SIZE })
              .then(resp => {
                this.characteristicsList = resp.payload
                this.pages = resp.totalPages
              })
            this.showModal()
          }
        })
    },
    editCharacteristic (data) {
      this.updateCharacteristic(data)
        .then(response => {
          if (response) {
            this.getCharacteristics({ page: 1, size: DEFAULT_DISPLAY_SIZE })
              .then(resp => {
                this.characteristicsList = resp.payload
                this.pages = resp.totalPages
              })
            this.modalEdit = false
          }
        })
    },
    searchCharacteristics ({ target }) {
      if (target.value.length >= 3 || target.value.length === 0) {
        this.search = target.value
        this.getCharacteristics({ page: 1, search: target.value, filter: this.filter })
          .then(resp => {
            this.characteristicsList = resp.payload
            this.pages = resp.totalPages
          })
      }
    },
    changeStatus ({ target }) {
      this.filter = target.value
      this.getCharacteristics({ page: 1, search: this.search, filter: target.value })
        .then(resp => {
          this.characteristicsList = resp.payload
          this.pages = resp.totalPages
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getCharacteristics({ page: this.page, size: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.characteristicsList = resp.payload
        this.pages = resp.totalPages
        this.loading = false
        this.getCatalogCategories()
          .then(list => {
            this.catalogCategoriesList = list
          })
      })
  }
}
</script>
<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
    .icon-action {
    cursor: pointer;
    color: #039DA1;
    font-size: 14px;
  }

  .icon-action:hover {
    color: var(--primary-color);
  }
  .field:not(:last-child) {
    margin-bottom: 0rem;
  }
</style>
